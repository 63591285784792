<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{api_key: currentUser.api_key}">

        <div class="row">
            <validated-input class="col-6" label="Title" v-model="model.title"
                             :rules="rules.title" :disabled="loading"/>

            <file-input class="c-file-input col-6" label="Image" v-model="model.image" :disabled="loading"/>
        </div>
        <div class="row">
            <div class="col">
                <image-info :width="6730" :height="4490"/>
            </div>
        </div>

        <div class="row">
            <validated-vue-select class="col-6" label="Type" type="time" v-model="model.type"
                                  :options="typeOptions"
                                  :rules="rules.type" :disabled="loading"/>

            <checkbox-input class="col-6" label="Disable" v-model="model.disabled"
                            :rules="rules.disabled" :disabled="loading"/>
        </div>
        <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
             loading-text="Saving..." :disabled="loading"/>

    </b-form>
</template>

<script>
import urls from '../../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'AddBanner',
    computed : { ...mapGetters(['currentUser']) },
    data () {
        return {
            addUrl : urls.admin.event.banner.addEdit,
            rules  : {
                title : {
                    required : true
                },
                image : {
                    required : true
                },
                type : {
                    required : true
                },
                disabled : {
                    required : false
                }
            },
            typeOptions : [
                { label : 'Home', value : 'Home' },
                { label : 'Service', value : 'Service' },
                { label : 'Training', value : 'Training' }
            ]
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Banner..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
